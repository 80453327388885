var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    { attrs: { app: "", color: "transparent", padless: "" } },
    [
      _c(
        "v-bottom-navigation",
        {
          staticClass: "rounded-t-xl",
          attrs: {
            color: "primary",
            fixed: "",
            dark: "",
            mandatory: "",
            "background-color": "#232126",
            grow: "",
            app: "",
            height: "64",
          },
        },
        [
          _c(
            "v-btn",
            { staticClass: "text-decoration-none", attrs: { to: "/s/home" } },
            [
              _c("span", { staticClass: "mt-1 f10" }, [
                _vm._v(_vm._s(_vm.$t("Home"))),
              ]),
              _c("iconly", {
                attrs: { name: "home", type: "light", size: "2x" },
              }),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "text-decoration-none",
              attrs: { to: "/s/explore" },
            },
            [
              _c("span", { staticClass: "mt-1 f10" }, [
                _vm._v(_vm._s(_vm.$t("Explore"))),
              ]),
              _c("iconly", {
                attrs: { name: "category", type: "light", size: "2x" },
              }),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "text-decoration-none",
              attrs: { to: "/s/contents/create" },
            },
            [
              _c("iconly", {
                staticClass: "pointer primary--text",
                attrs: { name: "plus", type: "bold", size: "3x" },
              }),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "text-decoration-none",
              attrs: { to: _vm.sp, href: _vm.sp },
            },
            [
              _c("span", { staticClass: "mt-1 f10" }, [
                _vm._v(_vm._s(_vm.$t("My page"))),
              ]),
              _c("iconly", {
                attrs: { name: "document", type: "light", size: "2x" },
              }),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "text-decoration-none",
              attrs: { to: "/s/profile" },
            },
            [
              _c("span", { staticClass: "mt-1 f10" }, [
                _vm._v(_vm._s(_vm.$t("Profile"))),
              ]),
              _c("iconly", {
                attrs: { name: "profile", type: "light", size: "2x" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }