var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("Header"),
      _c(
        "v-main",
        { staticClass: "my-2", attrs: { id: "basic-main" } },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [_c("router-view", { key: _vm.$route.path })],
            1
          ),
        ],
        1
      ),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }